import React from "react";
import * as styles from "./services.module.scss";
import PageLayout from "../components/layout/PageLayout";
import Masthead from "../components/layout/Masthead";
import Seo from "../components/layout/SEO";
import Intro from "../components/layout/Intro";
import ScrollReveal from "../components/layout/ScrollReveal";
import ServicesContainer from "../components/services/ServicesContainer";

import servicesAnim from "../assets/json/services.json";

const services = () => {
  return (
    <PageLayout includeCTA anim={servicesAnim}>
      <Seo title="Services - Simplicitic Innovations Sdn Bhd" />
      <Masthead title="Design + Development" tag="Our services" />
      <Intro>
        <ScrollReveal effect="slide-up" delay={50}>
          <p className={`h4 text-center ${styles["intro"]}`}>
            We focus on what we do best to help you grow your business. Our core
            services include user-friendly UX design, cutting edge technology,
            eCommerce solutions, and digital marketing.
          </p>
        </ScrollReveal>
      </Intro>
      <ServicesContainer />
    </PageLayout>
  );
};

export default services;
